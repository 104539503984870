import * as React from 'react';
import { render } from 'react-dom';
import { configure } from 'mobx';
import { RedocStandalone } from './components/';
import { querySelector } from './utils/dom';
import { initializeSentry } from './utils/sentry';
configure({
  useProxies: 'ifavailable'
});
export { Redoc, AppStore } from '.';
export const version = __REDOC_VERSION__;
function attributesMap(element: Element) {
  const res = {};
  const elAttrs = element.attributes;
  // tslint:disable-next-line
  for (let i = 0; i < elAttrs.length; i++) {
    const attrib = elAttrs[i];
    res[attrib.name] = attrib.value;
  }
  return res;
}
function parseOptionsFromElement(element: Element) {
  const attrMap = attributesMap(element);
  const res = {};
  for (const attrName in attrMap) {
    const optionName = attrName.replace(/-(.)/g, (_, $1) => $1.toUpperCase());
    res[optionName] = attrMap[attrName];
    // TODO: normalize options
  }
  return res;
}
export function init(specOrSpecUrl: string | any, options: any = {}, element: Element | null = querySelector('redoc'), callback?: (e?: Error) => void) {
  if (element === null) {
    throw new Error('"element" argument is not provided and <redoc> tag is not found on the page');
  }
  const allOptions = {
    ...options,
    ...parseOptionsFromElement(element)
  };
  if (!__IS_DEVELOPMENT__) {
    initializeSentry(allOptions?.environment);
  }
  let specUrl: string | undefined;
  let spec: object | undefined;
  if (typeof specOrSpecUrl === 'string') {
    specUrl = specOrSpecUrl;
  } else if (typeof specOrSpecUrl === 'object') {
    spec = specOrSpecUrl;
  }
  render(React.createElement(RedocStandalone, {
    spec,
    onLoaded: callback,
    specUrl,
    options: allOptions
  }, ['Loading...']), element);
}

/**
 * autoinit ReDoc if <redoc> tag is found on the page with "spec-url" attr
 */
function autoInit() {
  const element = querySelector('redoc');
  if (!element) {
    return;
  }
  const specUrl = element.getAttribute('spec-url');
  if (specUrl) {
    init(specUrl, {}, element);
  }
}
autoInit();