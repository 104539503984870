import { makeObservable, observable } from 'mobx';
import { OpenAPIExternalDocumentation, OpenAPIPath, OpenAPIServer, OpenAPIXAdditionalInfo, OpenAPIXCodeSample, OpenAPIXDeprecationInformation, OpenAPIXThrottlingInformation, OpenAPIXVersions } from '../../types';
import { extractExtensions, getOperationSummary, getStatusCodeType, isStatusCode, JsonPointer, memoize, mergeParams, normalizeServers, sortByField, sortByRequired } from '../../utils';
import { ContentItemModel, ExtendedOpenAPIOperation } from '../MenuBuilder';
import { IMenuItem, MenuItemType } from '../MenuStore';
import { OpenAPIParser } from '../OpenAPIParser';
import { RedocNormalizedOptions } from '../RedocNormalizedOptions';
import { FieldModel } from './Field';
import { ActivateableItem, GroupModel } from './Group.model';
import { RequestBodyModel } from './RequestBody';
import { ResponseModel } from './Response';
import { SecurityRequirementModel } from './SecurityRequirement';

/**
 * Operation model ready to be used by components
 */
export class OperationModel extends ActivateableItem implements IMenuItem {
  //#region IMenuItem fields
  id: string;
  absoluteIdx?: number;
  name: string;
  description?: string;
  type = ('operation' as 'operation');
  parent?: GroupModel;
  externalDocs?: OpenAPIExternalDocumentation;
  items: ContentItemModel[] = [];
  parentProduct?: ContentItemModel;
  childrenType?: MenuItemType;
  depth: number;
  @observable
  ready?: boolean = true;
  //#endregion

  pointer: string;
  operationId?: string;
  httpVerb: string;
  deprecated: boolean;
  path: string;
  servers: OpenAPIServer[];
  security: SecurityRequirementModel[];
  codeSamples: OpenAPIXCodeSample[];
  extensions: Dict<any>;
  xAdditionalInfo?: OpenAPIXAdditionalInfo;
  xDeprecationInformation?: OpenAPIXDeprecationInformation;
  xThrottlingInformation?: OpenAPIXThrottlingInformation[];
  xApiAdditionalInfo?: OpenAPIXAdditionalInfo;
  xIsEarlyAccess: boolean;
  xLandingPageDescription?: string;
  xVersions?: OpenAPIXVersions[];
  xScopes: string[];
  isSunsetted: boolean;
  isWebhook: boolean = false;
  docsUrl: string;
  constructor(private parser: OpenAPIParser, private operationSpec: ExtendedOpenAPIOperation, parent: GroupModel | undefined, private options: RedocNormalizedOptions, depth: number, isWebhook: boolean) {
    super(parent);
    makeObservable(this);
    this.pointer = JsonPointer.compile(['paths', operationSpec.pathName, operationSpec.httpVerb]);
    this.parentProduct = parent?.parentProduct;
    this.id = operationSpec.operationId !== undefined ? this.generateId(operationSpec.operationId) : parent !== undefined ? parent.id + this.pointer : this.pointer;
    this.name = getOperationSummary(operationSpec);
    this.description = operationSpec.description;
    this.depth = depth;
    this.externalDocs = operationSpec.externalDocs;
    this.deprecated = !!operationSpec.deprecated;
    this.httpVerb = operationSpec.httpVerb;
    this.deprecated = !!operationSpec.deprecated;
    this.operationId = operationSpec.operationId;
    this.codeSamples = operationSpec['x-code-samples'] || [];
    this.path = operationSpec.pathName;
    this.xAdditionalInfo = operationSpec['x-additionalInfo'];
    this.xDeprecationInformation = operationSpec['x-deprecationInformation'];
    this.xThrottlingInformation = operationSpec['x-throttling-info'];
    this.xApiAdditionalInfo = parser.spec.info['x-additionalInfo'];
    this.xIsEarlyAccess = !!operationSpec['x-is-early-access'];
    this.xVersions = operationSpec['x-versions'];
    this.xLandingPageDescription = operationSpec['x-landing-page-description'] || null;
    this.xScopes = operationSpec['x-scopes'] || [];
    this.isWebhook = isWebhook;
    this.isSunsetted = this.checkSunsetDate();
    const pathInfo = parser.byRef<OpenAPIPath>(JsonPointer.compile(['paths', operationSpec.pathName]));
    this.servers = normalizeServers(parser.specUrl, operationSpec.servers || pathInfo && pathInfo.servers || parser.spec.servers || []);
    this.security = (operationSpec.security || parser.spec.security || []).map(security => new SecurityRequirementModel(security, parser));
    if (options.showExtensions) {
      this.extensions = extractExtensions(operationSpec, options.showExtensions);
    }
    if (options.docsUrl) {
      this.docsUrl = options.docsUrl;
    }
  }
  expand() {
    if (this.parent) {
      this.parent.expand();
    }
  }
  collapse() {
    /* do nothing */
  }
  @memoize
  get requestBody() {
    return this.operationSpec.requestBody && new RequestBodyModel(this.parser, this.operationSpec.requestBody, this.options);
  }
  @memoize
  get parameters() {
    const _parameters = mergeParams(this.parser, this.operationSpec.pathParameters, this.operationSpec.parameters
    // TODO: fix pointer
    ).map(paramOrRef => new FieldModel(this.parser, paramOrRef, this.pointer, this.options));
    if (this.options.sortPropsAlphabetically) {
      sortByField(_parameters, 'name');
    }
    if (this.options.requiredPropsFirst) {
      sortByRequired(_parameters);
    }
    return _parameters;
  }
  @memoize
  get responses() {
    let hasSuccessResponses = false;
    const keys = Object.keys(this.operationSpec.responses || []).sort((a, b) => {
      if (b === 'default') {
        return 1;
      } else if (a === 'default') {
        return -1;
      }
      return a.localeCompare(b);
    });
    return keys.filter(code => {
      if (code === 'default') {
        return true;
      }
      if (getStatusCodeType(code) === 'success') {
        hasSuccessResponses = true;
      }
      return isStatusCode(code);
    }) // filter out other props (e.g. x-props)
    .map(code => {
      return new ResponseModel(this.parser, code, hasSuccessResponses, this.operationSpec.responses[code], this.options);
    });
  }
  checkSunsetDate() {
    const sunsetDate = this?.xDeprecationInformation?.sunset;
    if (!this.xDeprecationInformation || !sunsetDate) {
      return false;
    }
    return new Date(sunsetDate).getTime() <= new Date().getTime();
  }

  // todo: unify id generation logic in the next task
  private generateId(operationId: string) {
    let parent: ContentItemModel | undefined = this.parent;
    if (parent && parent.type === 'section') {
      parent = parent.parent;
    }
    const path = (parent ? parent.id + '/' : '') + operationId;
    return path.replace(/ /g, '-').toLowerCase();
  }
}