import React from 'react';
import { __ } from 'ib-i18n';
import { AdditionalVersioningInfo, AdditionalVersioningInfoBadge, AdditionalVersioningInfoBadgeLatest } from './styled.elements';
import { OpenAPIXVersions } from '../../types';
import { versioningTitle } from '../Versioning/utils';
interface AdditionalVersioningProps {
  versionInformation: OpenAPIXVersions;
}
export const AdditionalVersioning = ({
  versionInformation
}: AdditionalVersioningProps) => {
  const InfoBadge = versionInformation.latest ? AdditionalVersioningInfoBadgeLatest : AdditionalVersioningInfoBadge;
  const labelText = versioningTitle(versionInformation.latest);
  return <AdditionalVersioningInfo>
      {labelText}
      <InfoBadge>{'V' + versionInformation.versionNumber}</InfoBadge>
    </AdditionalVersioningInfo>;
};