import { observer } from 'mobx-react';
import * as React from 'react';
import { H1, H2, MiddlePanel, Row, Section } from '../../common-elements';
import { ContentItemModel } from '../../services/MenuBuilder';
import { GroupModel, OperationModel } from '../../services/models';
import { ExternalDocumentation } from '../ExternalDocumentation/ExternalDocumentation';
import { AdvancedMarkdown } from '../Markdown/AdvancedMarkdown';
import { Operation } from '../Operation/Operation';
import { IMenuItem } from '../../services';
import { ShareLink } from '../Link';
@observer
export class ContentItems extends React.Component<{
  items: ContentItemModel[];
}> {
  render() {
    const {
      items
    } = this.props;
    if (items.length === 0) {
      return null;
    }
    return items.map(item => <ContentItem item={item} key={item.id} />);
  }
}
export interface ContentItemProps {
  item: ContentItemModel;
  getItemByOperationId?: (operationId: string) => IMenuItem | undefined;
}
@observer
export class ContentItem extends React.Component<ContentItemProps> {
  render() {
    const {
      item,
      getItemByOperationId
    } = this.props;
    let content;
    const {
      type
    } = item;
    switch (type) {
      case 'category':
      case 'product':
      case 'module':
      case 'submodule':
        content = null;
        break;
      case 'section':
      case 'page':
        content = <MarkdownItem {...this.props} />;
        break;
      case 'operation':
        content = <OperationItem item={(item as OperationModel)} getItemByOperationId={getItemByOperationId} />;
        break;
      default:
        content = <MarkdownItem {...this.props} />;
    }
    return <>
        {content && <Section id={item.id} underlined={item.type === 'operation'}>
            {content}
          </Section>}
        {item.items && <ContentItems items={(item.items as ContentItemModel[])} />}
      </>;
  }
}
const middlePanelWrap = component => <MiddlePanel compact={true}>{component}</MiddlePanel>;
@observer
export class MarkdownItem extends React.Component<ContentItemProps> {
  render() {
    const {
      name,
      description,
      externalDocs,
      level
    } = (this.props.item as GroupModel);
    const Header = level === 2 ? H2 : H1;
    return <>
        <Row>
          <MiddlePanel compact={level !== 1}>
            <Header>
              <ShareLink to={this.props.item.id} />
              {name}
            </Header>
          </MiddlePanel>
        </Row>
        <AdvancedMarkdown source={description || ''} htmlWrap={middlePanelWrap} />
        {externalDocs && <Row>
            <MiddlePanel>
              <ExternalDocumentation externalDocs={externalDocs} />
            </MiddlePanel>
          </Row>}
      </>;
  }
}
@observer
export class OperationItem extends React.Component<{
  item: OperationModel;
  getItemByOperationId?: (operationId: string) => IMenuItem | undefined;
}> {
  render() {
    return <Operation operation={this.props.item} getItemByOperationId={this.props.getItemByOperationId} />;
  }
}