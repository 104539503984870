import { observer } from 'mobx-react';
import { __ } from 'ib-i18n';
import * as React from 'react';
import { Container, FooterWrapper, Grid, GridItemLeftSegment, GridItemRightSegment, LinkContainer, LinksWrapper, LogoSvg, NestedContainer, PrimaryLinkButton, ResearchPanelHeading, ResearchPanelText, StyledBox, ResponsiveFlex, StyledLink, TextComponent, SocialIconsWrapper, Badge, StyledLinkUnderline, RowFlex } from './styled.elements';
import logo from '../../images/infobip-logo-large.svg';
import helpIcon from '../../images/help-icon.svg';
import headphonesIcon from '../../images/headphones-icon.svg';
import documentIcon from '../../images/document-icon.svg';
import aLetterIcon from '../../images/a-letter-icon.svg';
import greenStatusIcon from '../../images/green-status-icon.svg';
import xLogoIcon from '../../images/x-logo.svg';
import linkedinLogoIcon from '../../images/linkedin-logo.svg';
import facebookLogoIcon from '../../images/facebook-logo.svg';
import githubLogoIcon from '../../images/github-logo.svg';
import discordLogoIcon from '../../images/discord-logo.svg';
import { FooterLinksE, ResolvedThemeInterface } from '../../theme';
interface FooterProps {
  docsUrl: string;
  theme?: ResolvedThemeInterface;
}
@observer
export class Footer extends React.Component {
  props: FooterProps;
  render() {
    const defaultLinks = [{
      id: FooterLinksE.TUTORIALS,
      description: __('Need assistance'),
      href: `${this.props.docsUrl}/tutorials`,
      text: __('Explore Infobip Tutorials'),
      icon: helpIcon
    }, {
      id: FooterLinksE.SUPPORT,
      href: this.props.theme?.links?.supportUrl || 'https://www.infobip.com/contact',
      description: __('Encountering issues'),
      text: __('Contact our support'),
      icon: headphonesIcon
    }, {
      id: FooterLinksE.RELEASE_NOTES,
      href: `${this.props.docsUrl}/release-notes`,
      text: __('Release Notes'),
      /* prettier-ignore */
      description: __('What\'s new? Check out'),
      icon: documentIcon
    }, {
      id: FooterLinksE.GLOSSARY,
      href: '/glossary-hub',
      text: __('Glossary'),
      icon: aLetterIcon,
      description: __('Unsure about a term? See')
    }];
    const links = this.props.theme?.footer ? defaultLinks.filter(link => this.props.theme?.footer?.links?.includes(link.id)) : defaultLinks;
    const currentYear = new Date().getFullYear().toString();
    return <FooterWrapper gap={this.props.theme?.footer?.gap}>
        {/* Top Part */}
        <Container>
          <Grid>
            {/* Left Segment */}
            <GridItemLeftSegment>
              <LogoSvg src={this.props.theme?.logo.url || logo} title="Infobip Logo" />
              {/* Left-aligned children */}
              <LinksWrapper>
                {links.map(link => <LinkContainer key={link.id}>
                    <NestedContainer>
                      <LogoSvg src={link.icon} />
                      <TextComponent>{link.description}</TextComponent>
                    </NestedContainer>
                    <StyledLinkUnderline href={link.href}>{link.text}</StyledLinkUnderline>
                  </LinkContainer>)}
              </LinksWrapper>
            </GridItemLeftSegment>

            {/* Right Segment */}
            {!this.props.theme?.footer?.hideRightPanel && <GridItemRightSegment>
                <ResearchPanelText>{__('Research panel')}</ResearchPanelText>
                <ResearchPanelHeading as="h5">
                  {__('Help shape the future of our products')}
                </ResearchPanelHeading>
                <PrimaryLinkButton href="https://www.infobip.com/research-panel">
                  {__('Learn more')}
                </PrimaryLinkButton>
              </GridItemRightSegment>}
          </Grid>
        </Container>

        {/* Bottom Part */}
        <Container>
          <ResponsiveFlex>
            <StyledBox>
              <ResponsiveFlex>
                <StyledLink href={this.props.theme?.links?.statusUrl || 'https://status.infobip.com/'}>
                  <Badge>
                    <LogoSvg src={greenStatusIcon} />
                    {__('Service status')}
                  </Badge>
                </StyledLink>
                <TextComponent>
                  {this.props.theme?.texts?.copyRight || __('Copyright @ 2006-{currentYear} Infobip ltd.', {
                  currentYear
                })}
                </TextComponent>
              </ResponsiveFlex>
            </StyledBox>
            {this.props.theme?.footer ? <StyledBox>
                <LogoSvg src="https://infobip-cdn-h0h7ekhqhgh4hgau.a02.azurefd.net/1g8x60m5haaeebc38sw9etdnqwq2orfxs6yjtxwklw767cqz71/powered-by-infobip.svg" title="Powered by Infobip" />
              </StyledBox> : <StyledBox>
                <ResponsiveFlex>
                  <RowFlex>
                    <StyledLink href="https://www.infobip.com/privacy-documents">
                      {__('Privacy documents')}
                    </StyledLink>
                    <StyledLink href="https://www.infobip.com/policies">
                      {__('Terms of use')}
                    </StyledLink>
                  </RowFlex>
                  <SocialIconsWrapper>
                    <StyledLink href="https://twitter.com/Infobip">
                      <LogoSvg src={xLogoIcon} />
                    </StyledLink>
                    <StyledLink href="https://www.linkedin.com/company/infobip">
                      <LogoSvg src={linkedinLogoIcon} />
                    </StyledLink>
                    <StyledLink href="https://www.facebook.com/infobip/">
                      <LogoSvg src={facebookLogoIcon} />
                    </StyledLink>
                    <StyledLink href="https://github.com/infobip">
                      <LogoSvg src={githubLogoIcon} />
                    </StyledLink>
                    <StyledLink href="https://discord.com/invite/G9Gr6fk2e4">
                      <LogoSvg src={discordLogoIcon} />
                    </StyledLink>
                  </SocialIconsWrapper>
                </ResponsiveFlex>
              </StyledBox>}
          </ResponsiveFlex>
        </Container>
      </FooterWrapper>;
  }
}