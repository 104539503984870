import { observer } from 'mobx-react';
import * as React from 'react';
import logo from '../../images/infobip-logo.svg';
import { __ } from 'ib-i18n';
import { AppStore } from '../../services';
import { NavBarLinksE, ResolvedThemeInterface } from '../../theme';
import { Icon, LogoContainer, LogoSvg, Nav, MenuButton, MenuItem, MenuSidebar, NavBarLi, NavBarUl, NavWrapper, PrimaryLinkButton, RightNav, StyledLink, UnstyledLink, StyledSpan } from './styled.elements';
import { isValidUrl } from '../../utils';
interface NavBarProps {
  store: AppStore;
  scrollEnabled: boolean;
  docsUrl: string;
}
interface NavBarState {
  currentUrl: string;
  prevScrollPos: number;
  theme?: ResolvedThemeInterface;
  isMobileMenuOpen: boolean;
}
interface DocsLink {
  id: NavBarLinksE;
  label: string;
  href: string;
  active: boolean;
  className?: string;
  target?: '_blank' | '_self';
}
class DocsLinksProvider {
  constructor(private readonly docsUrl: string, private readonly theme: ResolvedThemeInterface) {
    this.docsUrl = docsUrl;
    this.theme = theme;
  }
  docsLinks(): DocsLink[] {
    let links: DocsLink[] = [{
      id: NavBarLinksE.PRODUCTS,
      label: __('Product Documentation'),
      href: this.buildDocsUrl('/product-documentation'),
      active: false
    }, {
      id: NavBarLinksE.ESSENTIALS,
      label: __('Essentials'),
      href: this.buildDocsUrl('/essentials'),
      active: false
    }, {
      id: NavBarLinksE.TUTORIALS,
      label: __('Tutorials'),
      href: this.buildDocsUrl('/tutorials'),
      active: false
    }, {
      id: NavBarLinksE.RELEASE_NOTES,
      label: __('Release Notes'),
      href: this.buildDocsUrl('/release-notes'),
      active: false
    }, {
      id: NavBarLinksE.PUBLIC_ROADMAP,
      label: 'Roadmap',
      href: 'https://roadmap.infobip.com',
      target: '_blank',
      active: false
    }, {
      id: NavBarLinksE.INTEGRATIONS,
      label: __('Integrations'),
      href: this.buildDocsUrl('/integrations'),
      active: false
    }, {
      id: NavBarLinksE.API,
      label: __('API'),
      href: window.location.origin + '/docs/api',
      active: true
    }, {
      id: NavBarLinksE.SDKS,
      label: __('SDKs'),
      href: this.buildDocsUrl('/sdk'),
      active: false
    }];
    const navbarLinks = this.theme?.navbar?.links;
    if (navbarLinks) {
      links = links.filter(link => navbarLinks[link.id]).map(link => ({
        ...link,
        ...navbarLinks[link.id]
      }));
    }
    return links;
  }
  private buildDocsUrl = (path: string): string => `${this.docsUrl}${path}`;
}
@observer
export class NavBar extends React.Component<NavBarProps, NavBarState> {
  private readonly docsLinksProvider: DocsLinksProvider;
  constructor(props) {
    super(props);
    this.state = {
      currentUrl: window.location.href,
      prevScrollPos: window.scrollY,
      isMobileMenuOpen: false
    };
    this.docsLinksProvider = new DocsLinksProvider(this.props.docsUrl, this.props.store.options.theme);
  }
  handleLoginHover = () => {
    this.setState({
      currentUrl: window.location.href.replace(/^http:\/\//i, 'https://')
    });
  };
  handleLogoutOnClick = () => {
    this.props.store.logoutUser();
  };
  handleToggleMenu = () => {
    this.setState({
      isMobileMenuOpen: !this.state.isMobileMenuOpen
    });
  };
  render() {
    const customTheme = this.props.store.options.theme;
    const signupUrl = customTheme?.navbar?.links[NavBarLinksE.SIGNUP].href || this.props.store.options.signupUrl;
    const user = this.props.store.user;
    const loginUrl = this.props.store.options.loginUrl;
    const logoSrc = isValidUrl(customTheme.logo.url) ? customTheme.logo.url : logo;
    return <NavWrapper>
        <LogoContainer href={`${window.location.origin}/docs`}>
          <LogoSvg src={logoSrc} title="Infobip Logo" />
        </LogoContainer>

        <Nav>
          <NavBarUl>
            {this.docsLinksProvider.docsLinks().map((docsLink, index) => <NavBarLi key={`docs-link-${index}`} className={docsLink.active ? 'active' : docsLink.className || ''}>
                <UnstyledLink href={docsLink.href} target={docsLink.target}>
                  {docsLink.label}
                </UnstyledLink>
              </NavBarLi>)}
          </NavBarUl>
          <RightNav>
            {user.loggedIn ? <>
                <StyledSpan>{user.userName}</StyledSpan>
                <StyledLink onClick={this.handleLogoutOnClick}>{__('Log out')}</StyledLink>
              </> : <>
                <StyledLink onMouseEnter={this.handleLoginHover} href={loginUrl.concat(this.state.currentUrl)}>
                  {__('Log in')}
                </StyledLink>
                <PrimaryLinkButton href={signupUrl}>{__('Try for free')}</PrimaryLinkButton>
              </>}
            <MenuButton onClick={this.handleToggleMenu} className={this.state.isMobileMenuOpen ? 'open' : ''}>
              <Icon />
            </MenuButton>

            <MenuSidebar className={this.state.isMobileMenuOpen ? 'open' : ''}>
              {this.docsLinksProvider.docsLinks().map((docsLink, index) => <MenuItem key={`docs-link-${index}`}>
                  <UnstyledLink href={docsLink.href}>{docsLink.label}</UnstyledLink>
                </MenuItem>)}
            </MenuSidebar>
          </RightNav>
        </Nav>
      </NavWrapper>;
  }
}