import * as React from 'react';
import styled from '../styled-components';
import { NetworkErrorComponent } from './NetworkError/NetworkError';
const ErrorWrapper = styled.div`
  padding: 20px;
  color: red;
`;
export class ErrorBoundary extends React.Component<{
  children: React.ReactNode;
}, {
  error?: Error;
}> {
  constructor(props) {
    super(props);
    this.state = {
      error: undefined
    };
  }
  componentDidCatch(error) {
    this.setState({
      error
    });
    return false;
  }
  render() {
    if (this.state.error?.name === 'AxiosError') {
      return <NetworkErrorComponent />;
    }
    if (this.state.error) {
      return <ErrorWrapper>
          <h1>Something went wrong...</h1>
          <small> {this.state.error.message} </small>
        </ErrorWrapper>;
    }
    return React.Children.only(this.props.children);
  }
}