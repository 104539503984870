import styled from '../../styled-components';
export const SuccessorVersioningWrapper = styled.div<{
  isMobile?: boolean;
  isReady?: boolean;
}>`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  border-bottom: 1px solid rgba(38, 50, 56, 0.3);
  ${({
  isReady
}) => isReady ? '' : 'opacity: 0;'}
  ${({
  isMobile
}) => isMobile ? 'overflow-x: auto; overflow-y: hidden;' : undefined}

  a {
    color: ${props => props.theme.colors.text.primary};
    text-decoration: none;
  }
`;
export const SuccessorVersioningInternalWrapper = styled.div<{
  isMobile?: boolean;
}>`
  display: flex;
  flex-direction: row;
`;
export const VersionTabOuterWrapper = styled.div`
  padding: 4px;
  display: flex;
  align-items: center;
`;
export const VersionTabActiveOuterWrapper = styled(VersionTabOuterWrapper)`
  padding: 4px;
  font-weight: 600;
  border-bottom: 2px solid #fc6423;
`;
export const DropdownTabWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  transition-property: fill, color, background-color, outline;
  transition-duration: 0.2s;
`;
export const VersionTabWrapper = styled(DropdownTabWrapper)`
  padding: 8px;
  border-radius: 2px;
  :hover {
    background-color: #f4f4f2;
    cursor: pointer;
  }
`;
export const VersionTabSpan = styled.span`
  margin-right: 8px;
`;
export const VersioningInfoBadgeLatest = styled.span`
  font-weight: 600;
  font-style: normal;
  line-height: 12px;
  font-size: 12px;
  text-align: center;
  color: white;
  background-color: #fc6423;
  border-radius: 3px;
  padding: 4px;
  gap: 8px;
`;
export const VersioningInfoBadge = styled(VersioningInfoBadgeLatest)`
  color: #174061;
  border: 1px solid #174061;
  background-color: transparent;
`;
export const ArrowDownWrapper = styled.div`
  transition: transform 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  background-color: transparent;

  button {
    cursor: pointer;
  }
`;
export const DropdownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  outline: none;
  margin: 0;
  cursor: pointer;
  font-size: 16px;
  gap: 8px;

  padding: 8px;
  border-radius: 2px;
  :hover {
    background-color: #f4f4f2;
    cursor: pointer;
  }
`;
export const DROPDOWN_CONTENT_WIDTH = 300;
export const ALIGNMENT = 12;
export const DropdownContent = styled.div<{
  alignRight?: boolean;
}>`
  display: block;
  position: absolute;
  background-color: #ffffff;
  min-width: ${DROPDOWN_CONTENT_WIDTH}px;
  box-shadow: 0 4px 8px 0 #0000001f;
  margin-top: 16px;

  ${({
  alignRight
}) => alignRight ? `right: -${ALIGNMENT}px;` : `left: -${ALIGNMENT}px;`}
`;
export const DropDownMoreWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const DropdownItems = styled.div`
  padding: 8px 0;
`;
export const DropDownItem = styled.div<{
  isActive?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 8px;
  margin: 0 8px;
  ${({
  isActive
}) => isActive ? 'background-color: #f4f4f2;' : 'background-color: transparent;'}
    :hover {
    background-color: #f4f4f2;
    cursor: pointer;
  }
`;
export const DropDownItemText = styled.div`
  color: black;
  letter-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DropdownItemTextSpan = styled.span<{
  isActive?: boolean;
}>`
  margin-right: 8px;

  ${({
  isActive
}) => isActive ? 'font-weight: 600;' : 'font-weight: 400'}
`;