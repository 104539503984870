import * as React from 'react';
import { TitleSection, StyledUl } from '../HomePage/HomePageComponents';
import { H1 } from '../../../common-elements/headers';
import { MiddlePanel } from '../../../common-elements';
import { observer } from 'mobx-react';
import { StyledP } from '../../NavBar/styled.elements';
import { AppStore } from '../../../services';
import { FormattedHtmlMessage, __ } from 'ib-i18n';
@observer
export class EmailGetStarted extends React.Component<{
  store: AppStore;
}> {
  render() {
    const {
      docsUrl,
      portalUrl,
      signupUrl,
      loginUrl,
      docsUrlEncoded
    } = this.props.store.options;
    return <>
        <MiddlePanel compact={true} full={true}>
          <H1>{__('Get Started with Email')}</H1>
          <StyledP>
            {/* prettier-ignore */
          __('Send a simple Email message by defining destination and message text, or experiment with a rich set of features. With Infobip, you can create rich, personalized transactional or marketing emails at scale.')}
          </StyledP>
          <TitleSection>{__('Send your first email!')}</TitleSection>
          <StyledP>
            <ol>
              <li>
                <FormattedHtmlMessage message={/* prettier-ignore */
              __('<b>Infobip account</b> is required to send emails. {signup} or {login}, if you haven\'t already.')} signup={<a href={signupUrl}>{__('Create one')}</a>} login={<a href={`${loginUrl}/?callback=${docsUrlEncoded}%2Fapi%23channels%2Femail`}>
                      {__('log in')}
                    </a>} />
              </li>
              <li>
                <FormattedHtmlMessage message={__('<b>Demo sender ID</b> is provided to you during the trial period.')} />
              </li>
              <li>
                <FormattedHtmlMessage message={__('<b>Your email address</b> is the only one that can be used as an email recipient during the trial period - the one you used for the account registration.')} />
              </li>
            </ol>
          </StyledP>
          <TitleSection>{__('Other languages and advanced functionalities')}</TitleSection>
          <StyledP>
            <FormattedHtmlMessage message={__('For more information on usage and code examples explore {emailApiReference}.')} emailApiReference={<a href={`${docsUrl}/api/channels/email/send-fully-featured-email`}>
                  {__('Send fully featured email API reference')}
                </a>} />
          </StyledP>
          <TitleSection>{__('SMTP')}</TitleSection>
          <StyledP>
            <FormattedHtmlMessage message={__('If you are interested in sending email via SMTP, see our {smtpConfiguration}.')} smtpConfiguration={<a href={`${docsUrl}/email/smtp-specification`}>
                  {__('SMTP configuration tutorial')}
                </a>} />
          </StyledP>
          <TitleSection>{__('Next steps')}</TitleSection>
          <StyledUl>
            <li>
              <a href={`${docsUrl}/sdk`}>{__('Get API client library')}</a>
            </li>
            <li>
              <a href={`${portalUrl}/payments`}>{__('Upgrade account')}</a>
            </li>
            <li>
              <a href={`${portalUrl}/apps/email`}>{__('Register sender domain')}</a>
            </li>
            <li>
              <FormattedHtmlMessage message={__('Explore {sendApiReference} to learn how to send HTML or Templated emails')} sendApiReference={<a href={`${docsUrl}/api/channels/email/send-fully-featured-email`}>
                    {__('Send fully featured email API reference')}
                  </a>} />
            </li>
          </StyledUl>
          <TitleSection>{__('Need help?')}</TitleSection>
          <StyledP>
            <FormattedHtmlMessage message={__('In case you get stuck at any point, visit {getStarted} to find any information you need to successfully use Infobip API.')} getStarted={<a href={`${docsUrl}/api`}>{__('API Get started page')}</a>} />
          </StyledP>
        </MiddlePanel>
      </>;
  }
}