import * as PropTypes from 'prop-types';
import * as React from 'react';
import { AppStore } from '../../services';
import { ThemeProvider } from '../../styled-components';
import { ApiLogo } from '../ApiLogo/ApiLogo';
import { ContentItems } from '../ContentItems/ContentItems';
import { SingleContentItem } from '../ContentItems/SingleContentItem';
import { NavBar } from '../NavBar/NavBar';
import { OptionsProvider } from '../OptionsProvider';
import { SearchBox } from '../SearchBox/SearchBox';
import { SideMenu } from '../SideMenu/SideMenu';
import { StickyResponsiveSidebar } from '../StickySidebar/StickyResponsiveSidebar';
import { StoreProvider } from '../StoreBuilder';
import { ApiContentWrap, RedocWrap } from './styled.elements';
import { InfoBanner } from '../InfoBanner/InfoBanner';
import { Footer } from '../Footer/Footer';
export interface RedocProps {
  store: AppStore;
}
export class Redoc extends React.Component<RedocProps> {
  static propTypes = {
    store: PropTypes.instanceOf(AppStore).isRequired
  };
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.store.onDidMount();
  }
  componentWillUnmount() {
    this.props.store.dispose();
  }
  render() {
    const {
      store: {
        spec,
        menu,
        options,
        search,
        marker
      },
      store
    } = this.props;
    return <ThemeProvider theme={options.theme}>
        <StoreProvider value={store}>
          <OptionsProvider value={options}>
            <RedocWrap className="redoc-wrap">
              {options.navBarEnabled && <NavBar store={store} scrollEnabled={options.navBarScrollEnabled} docsUrl={options.docsUrl} /> || null}
              <StickyResponsiveSidebar theme={options.theme} menu={menu} className="menu-content">
                {!options.navBarEnabled && <ApiLogo info={spec.info} /> || null}
                {!options.disableSearch && <SearchBox search={search!} marker={marker} getItemById={menu.getItemById} onActivate={menu.activateAndScroll} store={store} /> || null}
                <SideMenu menu={menu} />
              </StickyResponsiveSidebar>
              <ApiContentWrap className="api-content">
                {options.showGeneratedAt && <InfoBanner spec={spec} environment={options.environment} />}
                {options.disableInfiniteScroll ? <SingleContentItem menu={menu} store={store} /> : <ContentItems items={menu.items} />}
                {options.footerEnabled && <Footer docsUrl={options.docsUrl} theme={options.theme} />}
              </ApiContentWrap>
              {/* <BackgroundStub /> */}
            </RedocWrap>
          </OptionsProvider>
        </StoreProvider>
      </ThemeProvider>;
  }
}