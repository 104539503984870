import styled from 'styled-components';
import { linksCss } from '../Markdown/styled.elements';
const heightNavMobile = '4.063rem';
export const NavWrapper = styled.div.withConfig({
  componentId: "sc-sq1qum-0"
})(["align-items:center;background-color:", ";border-bottom:1px solid ", ";color:", ";display:flex;height:", ";left:0;padding:0 1.25rem;position:fixed;right:0;top:0;z-index:1000;@media screen and (min-width:80rem){padding:0 2.5rem;height:5rem;}"], props => props.theme.navBar.backgroundColor, props => props.theme.colors.darks.secondary, props => props.theme.colors.text.tertiary, heightNavMobile);
export const LogoContainer = styled.a.withConfig({
  componentId: "sc-sq1qum-1"
})(["img{max-height:2.313rem;width:2.313rem;@media screen and (min-width:80rem){width:2.375rem;height:2.375rem;}}"]);
export const LogoSvg = styled.img.withConfig({
  componentId: "sc-sq1qum-2"
})(["margin:auto;position:relative;display:block;"]);
export const RightNav = styled.div.withConfig({
  componentId: "sc-sq1qum-3"
})(["display:flex;justify-content:end;flex:1;align-items:center;"]);
export const Nav = styled.div.withConfig({
  componentId: "sc-sq1qum-4"
})(["display:flex;flex:1;padding-left:0.938rem;"]);
export const NavBarUl = styled.ul.withConfig({
  componentId: "sc-sq1qum-5"
})(["display:none;list-style-type:none;visibility:hidden;padding-inline-start:unset;@media screen and (min-width:80rem){visibility:visible;display:flex;}"]);
export const NavBarLi = styled.li.withConfig({
  componentId: "sc-sq1qum-6"
})(["font-weight:600;padding:0.5rem 0.938rem;&.active{background-color:", ";border-radius:1.25rem;a{color:", ";}}"], props => props.theme.navBar.linkBGColorActive, props => props.theme.navBar.linkColorActive);
export const UnstyledLink = styled.a.withConfig({
  componentId: "sc-sq1qum-7"
})(["position:relative;text-decoration:none;color:", ";display:flex;z-index:2;&:hover{color:", ";fill:", ";}&.active{color:", ";background-color:", ";border-radius:1.25rem;}"], props => props.theme.colors.text.tertiary, props => props.theme.navBar.linkColorHover, props => props.theme.navBar.linkColorHover, props => props.theme.navBar.linkColorActive, props => props.theme.navBar.linkBGColorActive);
export const StyledLink = styled.a.withConfig({
  componentId: "sc-sq1qum-8"
})(["position:relative;text-decoration:none;color:", ";font-size:1rem;font-weight:600;display:flex;z-index:2;padding:0.5rem 1rem;&:hover{color:", ";fill:", ";cursor:", ";}&:active{color:", ";background-color:", ";border-radius:1.25rem;}"], props => props.theme.navBar.linkColorActive, props => props.theme.navBar.linkColorActive, props => props.theme.navBar.linkColorActive, props => props.onClick || props.href ? 'pointer' : 'default', props => props.theme.navBar.linkColorActive, props => props.theme.navBar.linkBGColorActive);
export const PrimaryLinkButton = styled.a.withConfig({
  componentId: "sc-sq1qum-9"
})(["background:", ";text-decoration:none;height:2rem;padding:1rem;color:", ";white-space:nowrap;appearance:none;user-select:none;outline:2px solid transparent;outline-offset:2px;border-radius:0.375rem;transition-property:common;transition-duration:normal;font-size:0.875rem;font-weight:600;line-height:0;display:flex;align-items:center;margin-left:0.5rem;@media screen and (min-width:80rem){font-size:1rem;height:2.5rem;}"], props => props.theme.colors.primary.main, props => props.theme.colors.lights.primary);
export const StyledP = styled.p.withConfig({
  componentId: "sc-sq1qum-10"
})(["", ";"], linksCss);
export const StyledDiv = styled.div.withConfig({
  componentId: "sc-sq1qum-11"
})(["", ""], linksCss);

// Hamburger Menu Button
export const MenuButton = styled.div.withConfig({
  componentId: "sc-sq1qum-12"
})(["display:flex;margin-left:1rem;padding:0.75rem;position:relative;&.open{> span{transform:rotate(45deg);}> span::before{top:0;transform:rotate(0);}> span::after{top:0;transform:rotate(90deg);}}@media screen and (min-width:80rem){visibility:hidden;display:none;}"]);
export const StyledSpan = styled.span.withConfig({
  componentId: "sc-sq1qum-13"
})(["position:relative;text-decoration:none;color:", ";font-size:1rem;font-weight:600;display:flex;z-index:2;padding:0.5rem 1rem;"], props => props.theme.navBar.linkColorActive);
export const Icon = styled.span.withConfig({
  componentId: "sc-sq1qum-14"
})(["transition:all 0.3s ease-in-out;left:0;position:relative;z-index:1001;background-image:url(\"data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.3183 2.68439C17.3771 2.74352 17.4216 2.81149 17.4518 2.884C17.4829 2.95817 17.5 3.03958 17.5 3.125V7.8125C17.5 8.15768 17.2202 8.4375 16.875 8.4375C16.5298 8.4375 16.25 8.15768 16.25 7.8125V4.63388L11.6919 9.19194C11.4479 9.43602 11.0521 9.43602 10.8081 9.19194C10.564 8.94786 10.564 8.55214 10.8081 8.30806L15.3661 3.75H12.1875C11.8423 3.75 11.5625 3.47018 11.5625 3.125C11.5625 2.77982 11.8423 2.5 12.1875 2.5H16.875C17.0469 2.5 17.2026 2.56941 17.3156 2.68173L17.3169 2.68306L17.3183 2.68439Z' fill='%231B1B1A'/%3E%3Cpath d='M3.75 5C3.41848 5 3.10054 5.1317 2.86612 5.36612C2.6317 5.60054 2.5 5.91848 2.5 6.25V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H13.75C14.0815 17.5 14.3995 17.3683 14.6339 17.1339C14.8683 16.8995 15 16.5815 15 16.25V11.25C15 10.9048 14.7202 10.625 14.375 10.625C14.0298 10.625 13.75 10.9048 13.75 11.25V16.25H3.75L3.75 6.25H8.75C9.09518 6.25 9.375 5.97018 9.375 5.625C9.375 5.27982 9.09518 5 8.75 5H3.75Z' fill='%231B1B1A'/%3E%3C/svg%3E%0A\");display:block;height:20px;width:20px;&,&::before,&::after{display:block;position:absolute;width:100%;height:2px;background-color:", ";transition-duration:0.3s;}&::before{content:'';top:-6px;}&::after{content:'';top:6px;}"], props => props.theme.colors.darks.primary);

// Hamburger Menu Sidebar
export const MenuSidebar = styled.ul.withConfig({
  componentId: "sc-sq1qum-15"
})(["display:flex;flex-direction:column;margin:0;gap:0.75rem;position:fixed;top:0;background-color:", ";box-shadow:1px 0px 6px rgba(0,0,0,0.2);z-index:1000;list-style:none;transition:all 0.3s ease-in-out;right:-100%;padding:5rem 3.125rem;bottom:0;min-width:18rem;border-left:1px solid ", ";&.open{right:0;}@media screen and (min-width:80rem){visibility:hidden;display:none;}"], props => props.theme.colors.lights.primary, props => props.theme.colors.lights.secondary);

// Menu Item Styles
export const MenuItem = styled.li.withConfig({
  componentId: "sc-sq1qum-16"
})(["width:fit-content;"]);