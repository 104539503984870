import * as React from 'react';
import { ArrowDownWrapper } from './styled.elements';
import arrowDownIcon from '../../images/arrow-down.svg';
interface ArrowDownSvgProps {
  isOpened?: boolean;
}
export const ArrowDownSvg = ({
  isOpened
}: ArrowDownSvgProps): React.JSX.Element => <ArrowDownWrapper style={{
  transform: isOpened ? 'rotate(180deg)' : 'rotate(0deg)'
}}>
    <img src={arrowDownIcon} alt={'arrowDown'} />
  </ArrowDownWrapper>;