import styled from 'styled-components';
import { linksCss } from '../Markdown/styled.elements';
export interface FooterWrapperProps {
  gap?: string;
}
export const FooterWrapper = styled.div.withConfig({
  componentId: "sc-ygrp15-0"
})(["display:flex;flex-direction:column;color:", ";background:", ";z-index:2;position:relative;left:0;right:0;width:100%;margin-left:0;padding:4rem ", "px;gap:", ";"], props => props.theme.colors.text.tertiary, props => props.theme.colors.border.primary, props => props.theme.spacing.sectionHorizontal, props => props.gap || '4rem');
export const Grid = styled.div.withConfig({
  componentId: "sc-ygrp15-1"
})(["display:grid;gap:3rem;grid-template-columns:1fr;@media screen and (min-width:", "){grid-template-columns:10fr 5fr;}@media screen and (min-width:", "){grid-template-columns:10fr 3fr;}"], props => props.theme.breakpoints.medium, props => props.theme.breakpoints.large);
export const GridItemLeftSegment = styled.div.withConfig({
  componentId: "sc-ygrp15-2"
})([""]);
export const GridItemRightSegment = styled.div.withConfig({
  componentId: "sc-ygrp15-3"
})(["justify-content:center;flex-direction:column;gap:8px;display:flex;border-top:1px solid ", ";border-bottom:1px solid ", ";padding:3rem 0;text-align:center;align-items:center;@media screen and (min-width:", "){text-align:left;align-items:start;min-width:15rem;border-top:0;border-bottom:0;padding-inline-start:24px;border-left:1px solid ", ";}@media screen and (min-width:", "){justify-content:start;}"], props => props.theme.colors.border.secondary, props => props.theme.colors.border.secondary, props => props.theme.breakpoints.medium, props => props.theme.colors.border.secondary, props => props.theme.breakpoints.medium);
export const LinksWrapper = styled.div.withConfig({
  componentId: "sc-ygrp15-4"
})(["display:flex;gap:24px;margin-top:32px;flex-direction:column;"]);
export const LinkContainer = styled.div.withConfig({
  componentId: "sc-ygrp15-5"
})(["display:flex;flex-direction:column;gap:8px;a{padding-left:2.063rem;}@media screen and (min-width:63rem){flex-direction:row;a{padding-left:0;}}"]);
export const NestedContainer = styled.div.withConfig({
  componentId: "sc-ygrp15-6"
})(["display:flex;flex-direction:row;gap:8px;"]);
export const TextComponent = styled.p.withConfig({
  componentId: "sc-ygrp15-7"
})(["margin:0;align-self:start;@media (min-width:62em){align-self:center;}"]);
export const StyledLinkUnderline = styled.a.withConfig({
  componentId: "sc-ygrp15-8"
})(["color:", ";font-weight:600;text-decoration:underline;"], props => props.theme.colors.active.primary);
export const StyledLink = styled.a.withConfig({
  componentId: "sc-ygrp15-9"
})(["color:", ";font-weight:600;text-decoration:none;height:fit-content;"], props => props.theme.colors.active.primary);
export const LogoSvg = styled.img.withConfig({
  componentId: "sc-ygrp15-10"
})(["height:fit-content;display:block;align-self:center;max-height:3.604rem;"]);
export const ResearchPanelText = styled.p.withConfig({
  componentId: "sc-ygrp15-11"
})(["color:", ";font-size:1rem;text-transform:uppercase;margin:0;"], props => props.theme.colors.active.secondary);
export const ResearchPanelHeading = styled.h5.withConfig({
  componentId: "sc-ygrp15-12"
})(["font-size:1.5rem;margin:0;"]);
export const PrimaryLinkButton = styled.a.withConfig({
  componentId: "sc-ygrp15-13"
})(["display:inline-flex;align-items:center;justify-content:center;appearance:none;user-select:none;position:relative;white-space:nowrap;vertical-align:middle;outline:2px solid transparent;outline-offset:2px;line-height:1.2;border-radius:0.375rem;transition-property:common;transition-duration:0.15s;height:2.5rem;min-width:2.5rem;font-size:1rem;font-weight:600;background:", ";color:", ";padding:0 1rem;min-width:2.5rem;width:fit-content;margin-top:24px;text-decoration:none;"], props => props.theme.colors.primary.main, props => props.theme.colors.lights.primary);
export const StyledP = styled.p.withConfig({
  componentId: "sc-ygrp15-14"
})(["", ";"], linksCss);
export const StyledDiv = styled.div.withConfig({
  componentId: "sc-ygrp15-15"
})(["", ""], linksCss);
export const ResponsiveFlex = styled.div.withConfig({
  componentId: "sc-ygrp15-16"
})(["display:flex;width:100%;gap:2rem;align-items:center;text-align:center;flex-direction:column;@media screen and (min-width:", "){gap:1rem;flex-direction:row;justify-content:space-between;}"], props => props.theme.breakpoints.medium);
export const LinksHorizontalWrapper = styled.div.withConfig({
  componentId: "sc-ygrp15-17"
})(["display:flex;flex-direction:column;gap:2rem;align-items:center;flex:1;@media screen and (min-width:70rem){gap:1rem;flex-direction:row;}"]);
export const SocialIconsWrapper = styled.div.withConfig({
  componentId: "sc-ygrp15-18"
})(["display:flex;flex-direction:row;gap:16px;@media screen and (min-width:", "){padding-inline-start:16px;border-left:1px solid ", ";}"], props => props.theme.breakpoints.medium, props => props.theme.colors.border.secondary);
export const Badge = styled.span.withConfig({
  componentId: "sc-ygrp15-19"
})(["white-space:nowrap;vertical-align:middle;text-transform:none;font-size:0.875rem;border-radius:16px;font-weight:600;background:", ":color:", ";box-shadow:inset 0 0 0 1px ", ";display:flex;direction:ltr;gap:6px;width:fit-content;padding:4px 8px;"], props => props.theme.colors.lights.secondary, props => props.theme.colors.lights.tertiary, props => props.theme.colors.lights.tertiary);
export const RightLinks = styled.div.withConfig({
  componentId: "sc-ygrp15-20"
})(["display:flex;flex-direction:column;gap:2rem;@media screen and (min-width:1123px){display:grid;gap:1rem;grid-template-columns:1fr 1fr;text-align:left;}@media screen and (min-width:", "){grid-template-columns:2fr 1fr;text-align:right;margin-left:auto;}"], props => props.theme.breakpoints.large);