import { action, makeObservable, observable } from 'mobx';
import { flattenByProp, GETTING_STARTED_SECTION_NAME, INDEX_PAGE_TITLE, INFOBIP_API, SECURITY_SCHEMES_SECTION_PREFIX } from '../utils';
import { querySelector } from '../utils/dom';
import { HistoryService } from './HistoryService';
import { ContentItemModel } from './MenuBuilder';
import { OperationModel, SpecStore } from './models';
import { ScrollService } from './ScrollService';
import { SearchEventProcessor } from './SearchEventProcessor';
import { Found } from './models/SearchEvent';
export type MenuItemGroupType = 'category' | 'product' | 'module' | 'submodule' | 'section' | 'page';
export type MenuItemType = MenuItemGroupType | 'operation';

/** Generic interface for MenuItems */
export interface IMenuItem {
  id: string;
  absoluteIdx?: number;
  name: string;
  description?: string;
  depth: number;
  active: boolean;
  expanded: boolean;
  items: ContentItemModel[];
  parent?: ContentItemModel;
  deprecated?: boolean;
  type: MenuItemType;
  deactivate(): void;
  activate(): void;
  collapse(): void;
  expand(): void;
}
export const SECTION_ATTR = 'data-section-id';
export interface ActivateType {
  item: IMenuItem | undefined;
  updateLocation?: boolean;
  rewriteHistory?: boolean;
  term?: string;
  link?: string;
}

/**
 * Stores all side-menu related information
 */
export class MenuStore {
  /**
   * current active item
   */
  get activeItem(): IMenuItem {
    return this.flatItems[this.activeItemIdx] || undefined;
  }
  private static extractLastPartOfUrl(url: string): string {
    const urlPartitions = url.split('/');
    if (urlPartitions.length === 0) {
      return '';
    }
    return urlPartitions[urlPartitions.length - 1];
  }

  /**
   * active item absolute index (when flattened). -1 means nothing is selected
   */
  @observable
  activeItemIdx: number = -1;

  /**
   * whether sidebar with menu is opened or not
   */
  @observable
  sideBarOpened: boolean = false;
  items: ContentItemModel[];
  flatItems: ContentItemModel[];

  /**
   * cached flattened menu items to support absolute indexing
   */
  private _unsubscribe: () => void;
  private _hashUnsubscribe: () => void;

  /**
   *
   * @param spec [SpecStore](#SpecStore) which contains page content structure
   * @param scroll scroll service instance used by this menu
   */
  constructor(spec: SpecStore, public scroll: ScrollService, public history: HistoryService, public searchProcessor?: SearchEventProcessor) {
    makeObservable(this);
    this.items = spec.contentItems;
    this.flatItems = flattenByProp(this.items || [], 'items');
    this.removeSunsetOpFromContentItems(this.items);
    this.removeSunsettedOperations();
    this.flatItems.forEach((item, idx) => item.absoluteIdx = idx);
    this.subscribe();
  }
  removeSunsetOpFromContentItems(items) {
    items.forEach((item, index) => {
      if (item.type === 'operation' && item.xDeprecationInformation?.sunset) {
        const sunset = new Date(item.xDeprecationInformation.sunset).getTime();
        const now = new Date().getTime();
        if (sunset <= now) {
          items.splice(index, 1);
          this.removeSunsetOpFromContentItems(items);
          return;
        }
      }
      if (item.items?.length) {
        this.removeSunsetOpFromContentItems(item.items);
      }
    });
  }

  /**
   * Statically try update scroll position
   * Used before hydrating from server-side rendered html to scroll page faster
   */
  updateOnHistoryWithScroll(id: string = this.history.currentId, scroll: ScrollService) {
    if (!id) {
      return;
    }
    scroll.scrollIntoViewBySelector(`[${SECTION_ATTR}="${id}"]`);
  }
  subscribe() {
    this._unsubscribe = this.scroll.subscribe(this.updateOnScroll);
    this._hashUnsubscribe = this.history.subscribe(this.updateOnHistory);
  }
  @action
  toggleSidebar() {
    this.sideBarOpened = this.sideBarOpened ? false : true;
  }
  @action
  closeSidebar() {
    this.sideBarOpened = false;
  }

  /**
   * update active items on scroll
   * @param isScrolledDown whether last scroll was downside
   */
  updateOnScroll = (isScrolledDown: boolean): void => {
    const step = isScrolledDown ? 1 : -1;
    let itemIdx = this.activeItemIdx;
    while (true) {
      if (itemIdx === -1 && !isScrolledDown) {
        break;
      }
      if (itemIdx >= this.flatItems.length - 1 && isScrolledDown) {
        break;
      }
      if (isScrolledDown) {
        const el = this.getElementAtOrFirstChild(itemIdx + 1);
        if (this.scroll.isElementBellow(el)) {
          break;
        }
      } else {
        const el = this.getElementAt(itemIdx);
        if (this.scroll.isElementAbove(el)) {
          break;
        }
      }
      itemIdx += step;
    }
    this.activate(this.flatItems[itemIdx], true, true);
  };

  /**
   * update active items on hash change
   * @param id current hash
   */
  updateOnHistory = (id: string = this.history.currentId) => {
    if (id.endsWith('/')) {
      id = id.substr(0, id.length - 1);
    }
    let item: IMenuItem | undefined;
    const extractedId = MenuStore.extractLastPartOfUrl(id);
    item = this.flatItems.find(i => MenuStore.extractLastPartOfUrl(i.id) === extractedId);
    if (item) {
      if ((this.activeItem && this.activeItem.id) === (item && item.id)) {
        return;
      }
      this.activateAndScroll({
        item,
        updateLocation: true
      });
    } else {
      if (id.startsWith(SECURITY_SCHEMES_SECTION_PREFIX)) {
        item = this.flatItems.find(i => SECURITY_SCHEMES_SECTION_PREFIX.startsWith(i.id));
        this.activate(item);
      }
      this.scroll.scrollIntoViewBySelector(`[${SECTION_ATTR}="${id}"]`);
    }
  };

  /**
   * get section/operation DOM Node related to the item or null if it doesn't exist
   * @param idx item absolute index
   */
  getElementAt(idx: number): Element | null {
    const item = this.flatItems[idx];
    return item && querySelector(`[${SECTION_ATTR}="${item.id}"]`) || null;
  }

  /**
   * get section/operation DOM Node related to the item or if it is group item, returns first item of the group
   * @param idx item absolute index
   */
  getElementAtOrFirstChild(idx: number): Element | null {
    let item = this.flatItems[idx];
    if (item && (item.type === 'product' || item.type === 'module' || item.type === 'submodule')) {
      item = item.items[0];
    }
    return item && querySelector(`[${SECTION_ATTR}="${item.id}"]`) || null;
  }
  getItemById = (id: string) => {
    return this.flatItems.find(item => item.id === id);
  };
  getItemByOperationId = (operationId: string): OperationModel | undefined => {
    return (this.flatItems.filter(item => item.type === 'operation').find(item => (item as OperationModel).operationId === operationId) as OperationModel);
  };

  /**
   * activate menu item
   * @param item item to activate
   * @param updateLocation [true] whether to update location
   * @param rewriteHistory [false] whether to rewrite browser history (do not create new enrty)
   */
  @action
  activate(item: IMenuItem | undefined, updateLocation: boolean = true, rewriteHistory: boolean = false) {
    if (this.activeItem && item && this.activeItem.id === item.id) {
      if (this.activeItem.expanded) {
        this.activeItem.collapse();
      } else {
        this.activeItem.expand();
      }
      return;
    }
    if (item && item.type === 'section') {
      return;
    }
    this.deactivate(this.activeItem);
    if (!item) {
      this.history.replace('', rewriteHistory);
      return;
    }
    this.activeItemIdx = item.absoluteIdx!;
    if (updateLocation) {
      this.history.replace(item.id, rewriteHistory);
    }
    const itemName = item.name === GETTING_STARTED_SECTION_NAME ? INDEX_PAGE_TITLE : `${item.name} - ${INFOBIP_API}`;
    this.updateTitle(itemName);
    item.activate();
    item.expand();
  }

  /**
   * makes item and all the parents not active
   * @param item item to deactivate
   */
  deactivate(item: IMenuItem | undefined) {
    if (item === undefined) {
      return;
    }
    item.deactivate();
    while (item !== undefined) {
      item.collapse();
      item = item.parent;
    }
  }

  /**
   * activate menu item and scroll to it
   * @see MenuStore.activate
   */
  @action.bound
  activateAndScroll(activateType: ActivateType) {
    // item here can be a copy from search results so find corresponding item from menu
    const {
      item,
      term,
      link,
      updateLocation,
      rewriteHistory
    } = activateType;
    const menuItem = item && this.getItemById(item.id) || item;
    if (term && link) {
      this.searchProcessor!.process(new Found(term, link));
    }
    this.activate(menuItem, updateLocation, rewriteHistory);
    window.scrollTo(0, 0);
    if (!menuItem || !menuItem.items.length) {
      this.closeSidebar();
    }
  }
  dispose() {
    this._unsubscribe();
    this._hashUnsubscribe();
  }
  updateTitle(newTitle: string) {
    const title = querySelector('title');
    if (!title) {
      return;
    }
    title.textContent = newTitle;
  }
  removeSunsettedOperations() {
    this.flatItems = this.flatItems.filter(item => {
      if (item.type !== 'operation' || !item.xDeprecationInformation?.sunset) {
        return item;
      }
      const sunset = new Date(item.xDeprecationInformation.sunset).getTime();
      const now = new Date().getTime();
      if (sunset >= now) {
        return item;
      }
    });
  }
}