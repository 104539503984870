import * as React from 'react';
import styled from '../../styled-components';
import { __ } from 'ib-i18n';
import { Spinner } from '../Loading/Spinner.svg';
const ErrorWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
  background: #f4f5fc;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Box = styled.div`
  max-width: 22rem;
  background: #fff;
  padding: 1.8rem;
  border-radius: 16px;
  color: #545452;
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  h4 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #131336;
  }
`;
const Link = styled.a`
  padding: 0.7rem;
  background: #f2f5fa;
  border-radius: 8px;
  margin-top: 2rem;
  display: flex;
  text-decoration: none;
  width: fit-content;
  font: 16px;
  font-weight: bold;
  color: #2176ae;
  &:visited {
    color: #2176ae;
  }
`;
const SpinnerContainer = styled.div`
  margin-left: 25px;
`;
interface NetworkErrorProps {
  docsUrl: string | undefined;
}
export const NetworkErrorComponent: React.FC<NetworkErrorProps> = ({
  docsUrl
}) => {
  return <ErrorWrapper>
      <Box>
        <SpinnerContainer>
          <Spinner color="#2176AE" />
        </SpinnerContainer>
        <h4>{__('Network issue')}</h4>
        <p>
          {__('Please check your internet connection and refresh the page. If this keeps happening, try clearing your cache and cookies.')}
        </p>
        <Link href={`${docsUrl}/api`}>{__('Refresh')}</Link>
      </Box>
    </ErrorWrapper>;
};