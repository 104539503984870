import * as React from 'react';
import { __ } from 'ib-i18n';
import { ContentItemModel } from '../../services';
import { ShelfIcon } from '../../common-elements';
import { BackButtonLabel } from './styled.elements';
import { observer } from 'mobx-react';
import { SideMenuLink } from '../Link';
@observer
export class BackButton extends React.Component<{
  item?: ContentItemModel;
}> {
  render() {
    const backItem = this.props.item?.parentProduct?.parent;
    if (backItem) {
      return <BackButtonLabel>
          <SideMenuLink to={backItem.id}>
            <ShelfIcon float={'left'} direction={'left'} />
            {__('Back to all APIs')}
          </SideMenuLink>
        </BackButtonLabel>;
    }
    return null;
  }
}