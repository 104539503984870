import * as React from 'react';
import { OpenAPIXVersions } from '../../types';
import { IMenuItem } from '../../services';
import { SuccessorVersioningInternalWrapper, SuccessorVersioningWrapper, VersionTabActiveOuterWrapper, VersionTabOuterWrapper } from './styled.elements';
import { VersionTab } from './VersionTab';
import { DropdownMenu } from './DropdownMenu';
import { filteredVersionInformationList, findPathForSuccessorOperation, getDropdownItemTitle, isActive } from './utils';
import { useResizeObserver } from './hooks';
import { SideMenuLink } from '../Link';
interface SuccessorVersioningProps {
  currentOperationId: string;
  versionInformationList: OpenAPIXVersions[];
  getItemByOperationId: (operationId: string) => IMenuItem | undefined;
}
export const VersioningTabs = ({
  currentOperationId,
  versionInformationList,
  getItemByOperationId
}: SuccessorVersioningProps): React.JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  const internalContainerRef = React.useRef<HTMLDivElement | null>(null);
  const dropdownButtonOuterRef = React.useRef<HTMLDivElement | null>(null);
  const [filteredVersionInfoList] = React.useState<OpenAPIXVersions[]>(filteredVersionInformationList(versionInformationList, getItemByOperationId));
  const [visibleTabs, setVisibleTabs] = React.useState<OpenAPIXVersions[]>(filteredVersionInfoList);
  const [overflowedTabs, setOverflowedTabs] = React.useState<OpenAPIXVersions[]>([]);
  const [isFromOverflowedTabs, setIsFromOverflowedTabs] = React.useState<boolean>(false);
  const [tabWidths, setTabWidths] = React.useState<Array<{
    id: string;
    width: number;
  }>>([]);
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  const [isReady, setIsReady] = React.useState<boolean>(false);
  const OuterWrapper = isFromOverflowedTabs ? VersionTabActiveOuterWrapper : VersionTabOuterWrapper;
  const {
    width: containerWidth
  } = useResizeObserver(containerRef);
  const {
    width: internalWidth
  } = useResizeObserver(internalContainerRef);
  const MOBILE_WIDTH = 766;
  React.useEffect(() => {
    handleResize();
    if (tabWidths.length === 0) {
      storeTabWidths();
    }
    checkTabsOverflow();
  }, [containerWidth, internalWidth, versionInformationList]);
  React.useEffect(() => {
    setIsFromOverflowedTabs(overflowedTabs.some(value => value.operationId === currentOperationId));
  }, [overflowedTabs, currentOperationId]);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= MOBILE_WIDTH);
  };
  const getTabId = (operationId: string) => `tab-${operationId}`;
  const storeTabWidths = () => {
    const widths: Array<{
      id: string;
      width: number;
    }> = [];
    filteredVersionInfoList.forEach(versionInfo => {
      const tabElement = document.getElementById(getTabId(versionInfo.operationId));
      if (tabElement) {
        widths.push({
          id: versionInfo.operationId,
          width: tabElement.offsetWidth
        });
      }
    });
    setTabWidths(widths); // Store widths with the tab ID
    setIsReady(true);
  };
  const checkTabsOverflow = () => {
    if (!containerRef.current || !internalContainerRef.current) {
      return;
    }
    handleOverflow();
  };
  const handleOverflow = () => {
    if (isMobile || filteredVersionInfoList.length <= 2) {
      setVisibleTabs(filteredVersionInfoList);
      setOverflowedTabs([]);
      return;
    }
    const newVisibleTabs: OpenAPIXVersions[] = [];
    const newOverflowedTabs: OpenAPIXVersions[] = [];
    let totalWidth = 0;
    if (overflowedTabs.length !== 0 && dropdownButtonOuterRef.current) {
      totalWidth += dropdownButtonOuterRef.current.offsetWidth;
    }
    for (const versionInfo of filteredVersionInfoList) {
      const versionTab = tabWidths.find(tab => tab.id === versionInfo.operationId);
      const tabWidth = versionTab ? versionTab.width : 0;
      if (totalWidth + tabWidth <= containerWidth) {
        newVisibleTabs.push(versionInfo);
        totalWidth += tabWidth;
      } else {
        break;
      }
    }
    newOverflowedTabs.push(...filteredVersionInfoList.slice(newVisibleTabs.length));
    if (newOverflowedTabs.length === 1) {
      newOverflowedTabs.unshift((newVisibleTabs.pop() as OpenAPIXVersions));
    }
    if (!arraysEqual(visibleTabs, newVisibleTabs)) {
      setVisibleTabs(newVisibleTabs);
      setOverflowedTabs(newOverflowedTabs);
    }
  };
  const arraysEqual = (a: OpenAPIXVersions[], b: OpenAPIXVersions[]) => {
    return a.length === b.length && a.every((val, index) => val.operationId === b[index].operationId);
  };
  return <SuccessorVersioningWrapper ref={containerRef} isMobile={isMobile} isReady={isReady}>
      <SuccessorVersioningInternalWrapper ref={internalContainerRef} isMobile={isMobile}>
        {visibleTabs.map(versionInformation => <SideMenuLink key={versionInformation.operationId} to={findPathForSuccessorOperation(versionInformation, getItemByOperationId)}>
            <VersionTab id={getTabId(versionInformation.operationId)} active={isActive(versionInformation.operationId, currentOperationId)} versionInformation={versionInformation} title={getDropdownItemTitle(versionInformation, filteredVersionInfoList, getItemByOperationId)} />
          </SideMenuLink>)}
        {overflowedTabs.length > 0 && <OuterWrapper ref={dropdownButtonOuterRef}>
            <DropdownMenu overflowedTabs={overflowedTabs} versionInformationList={filteredVersionInfoList} currentOperationId={currentOperationId} getItemByOperationId={getItemByOperationId} containerRef={containerRef} />
          </OuterWrapper>}
      </SuccessorVersioningInternalWrapper>
    </SuccessorVersioningWrapper>;
};