import { OpenAPIXVersions } from '../../types';
import { VersioningInfoBadgeLatest, VersioningInfoBadge, VersionTabWrapper, VersionTabSpan, VersionTabActiveOuterWrapper, VersionTabOuterWrapper } from './styled.elements';
import React from 'react';
import { __ } from 'ib-i18n';
import { versioningTitle } from './utils';
interface VersionTabProps {
  id: string;
  active: boolean;
  versionInformation: OpenAPIXVersions;
  title?: string;
}
export const VersionTab = ({
  id,
  active,
  versionInformation,
  title
}: VersionTabProps): React.JSX.Element => {
  const InfoBadge = versionInformation.latest ? VersioningInfoBadgeLatest : VersioningInfoBadge;
  const OuterWrapper = active ? VersionTabActiveOuterWrapper : VersionTabOuterWrapper;
  const {
    latest,
    versionNumber
  } = versionInformation;
  const labelText = versioningTitle(latest, title);
  return <OuterWrapper id={id}>
      <VersionTabWrapper>
        <VersionTabSpan>{labelText + ' '}</VersionTabSpan>
        <InfoBadge>{'V' + versionNumber}</InfoBadge>
      </VersionTabWrapper>
    </OuterWrapper>;
};