import { observer } from 'mobx-react';
import * as React from 'react';
import { HomeIcon, ShelfIcon } from '../../common-elements/shelfs';
import { ActivateType, CategoryMenuItem, ContentItemModel, OperationModel } from '../../services';
import { assertNever } from '../../utils/helpers';
import { shortenHTTPVerb } from '../../utils/openapi';
import { MenuGroup } from './MenuGroup';
import { MenuItems } from './MenuItems';
import { MenuItemLabel, MenuItemLi, MenuItemTitle, OperationBadge, TooltipWebhook, WebhookBadge } from './styled.elements';
import { Link } from '../../common-elements';
import { AdditionalVersioning } from './AdditionalVersioning';
export interface MenuItemProps {
  item: ContentItemModel;
  onActivate?: (activateType: ActivateType) => void;
  withoutChildren?: boolean;
  term?: string;
  children?: React.ReactNode;
}
@observer
export class MenuItem extends React.Component<MenuItemProps> {
  ref = React.createRef<HTMLLabelElement>();
  activate = (evt: React.MouseEvent<HTMLElement>) => {
    this.props.onActivate!({
      item: this.props.item,
      term: this.props.term,
      link: this.props.item.id
    });
    evt.stopPropagation();
  };
  componentDidMount() {
    this.scrollIntoViewIfActive();
  }
  componentDidUpdate() {
    this.scrollIntoViewIfActive();
  }
  scrollIntoViewIfActive() {
    if (this.props.item.active && this.ref.current) {
      this.ref.current.scrollIntoViewIfNeeded();
    }
  }
  render() {
    const {
      item,
      withoutChildren
    } = this.props;
    return <Link to={item.id}>
        <MenuItemLi depth={item.depth} data-item-id={item.id}>
          {this.renderItem(item)}
          {!withoutChildren && item.items && item.items.length > 0 && <MenuItems expanded={item.expanded} items={item.items} onActivate={this.props.onActivate} />}
        </MenuItemLi>
      </Link>;
  }
  renderItem(item: ContentItemModel) {
    switch (item.type) {
      case 'page':
        return this.getMarkdownMenuItem(item);
      case 'category':
        return <MenuGroup item={(item as CategoryMenuItem)} />;
      case 'product':
        return <MenuItemLabel depth={item.depth} active={item.active} type={item.type} ref={this.ref} childrenType={item.childrenType} hasActiveChild={item.hasActiveChild}>
            <MenuItemTitle title={item.name}>
              {item.name}
              {this.props.children}
            </MenuItemTitle>
            {item.active}
            {item.hasActiveChild}
          </MenuItemLabel>;
      case 'module':
        return <MenuItemLabel depth={item.depth} active={item.active} type={item.type} ref={this.ref} childrenType={item.childrenType} hasActiveChild={item.hasActiveChild}>
            {(item.childrenType === 'operation' || item.childrenType === 'section') && <ShelfIcon float={'left'} direction={item.expanded ? 'down' : 'right'} />}
            <MenuItemTitle title={item.name}>
              {item.name}
              {this.props.children}
            </MenuItemTitle>
          </MenuItemLabel>;
      case 'submodule':
        return this.getMarkdownMenuItem(item);
      case 'section':
        return <MenuItemLabel depth={item.depth} active={item.active} type={item.type} ref={this.ref}>
            <MenuItemTitle title={item.name}>
              {item.name}
              {this.props.children}
            </MenuItemTitle>
          </MenuItemLabel>;
      case 'operation':
        return <OperationMenuItemContent {...this.props} item={item} />;
      default:
        return assertNever(item);
    }
  }
  private getMarkdownMenuItem(item: ContentItemModel) {
    return <MenuItemLabel depth={item.depth} active={item.active} type={item.type} ref={this.ref} childrenType={item.childrenType} hasActiveChild={item.hasActiveChild}>
        {!item.absoluteIdx && <HomeIcon />}
        {item.type === 'submodule' && <ShelfIcon float={'left'} direction={item.expanded ? 'down' : 'right'} /> || null}
        <MenuItemTitle title={item.name}>
          {item.name}
          {this.props.children}
        </MenuItemTitle>
      </MenuItemLabel>;
  }
}
export interface OperationMenuItemContentProps {
  item: OperationModel;
  children?: React.ReactNode;
}
@observer
export class OperationMenuItemContent extends React.Component<OperationMenuItemContentProps> {
  ref = React.createRef<HTMLLabelElement>();
  componentDidUpdate() {
    if (this.props.item.active && this.ref.current) {
      this.ref.current.scrollIntoViewIfNeeded();
    }
  }
  findMatchingVersionInformation = () => {
    const {
      item
    } = this.props;
    return item.xVersions?.find(versionInfo => versionInfo.operationId === item.operationId);
  };
  render() {
    const {
      item
    } = this.props;
    const versionInformation = this.findMatchingVersionInformation();
    return !item.isSunsetted && <MenuItemLabel type={item.type} depth={item.depth} active={item.active} deprecated={item.deprecated} ref={this.ref} childrenType={item.childrenType}>
          <OperationBadge type={item.httpVerb}>{shortenHTTPVerb(item.httpVerb)}</OperationBadge>
          <MenuItemTitle>
            {item.isWebhook && <WebhookBadge>
                WH <TooltipWebhook>WEBHOOK</TooltipWebhook>
              </WebhookBadge>}
            {item.name}
            {versionInformation && <AdditionalVersioning versionInformation={versionInformation} />}
            {this.props.children}
          </MenuItemTitle>
        </MenuItemLabel>;
  }
}