import { darken, desaturate, lighten, readableColor, transparentize } from 'polished';
const defaultTheme: ThemeInterface = {
  spacing: {
    unit: 5,
    sectionHorizontal: ({
      spacing
    }) => spacing.unit * 8,
    sectionVertical: ({
      spacing
    }) => spacing.unit * 8
  },
  breakpoints: {
    small: '50rem',
    medium: '85rem',
    large: '105rem'
  },
  colors: {
    tonalOffset: 0.3,
    primary: {
      main: '#fc6423',
      light: ({
        colors
      }) => lighten(colors.tonalOffset, colors.primary.main),
      dark: ({
        colors
      }) => darken(colors.tonalOffset, colors.primary.main),
      contrastText: ({
        colors
      }) => readableColor(colors.primary.main)
    },
    success: {
      main: '#00aa13',
      light: ({
        colors
      }) => lighten(colors.tonalOffset, colors.success.main),
      dark: ({
        colors
      }) => darken(colors.tonalOffset, colors.success.main),
      contrastText: ({
        colors
      }) => readableColor(colors.success.main)
    },
    warning: {
      main: '#d4ad03',
      light: ({
        colors
      }) => lighten(colors.tonalOffset, colors.warning.main),
      dark: ({
        colors
      }) => darken(colors.tonalOffset, colors.warning.main),
      contrastText: '#ffffff'
    },
    error: {
      main: '#e53935',
      light: ({
        colors
      }) => lighten(colors.tonalOffset, colors.error.main),
      dark: ({
        colors
      }) => darken(colors.tonalOffset, colors.error.main),
      contrastText: ({
        colors
      }) => readableColor(colors.error.main)
    },
    deprecated: {
      main: '#d40350',
      light: ({
        colors
      }) => lighten(colors.tonalOffset, colors.deprecated.main),
      dark: ({
        colors
      }) => darken(colors.tonalOffset, colors.deprecated.main),
      contrastText: '#ffffff'
    },
    webhook: {
      main: '#b8b894',
      light: ({
        colors
      }) => lighten(colors.tonalOffset, colors.webhook.main),
      dark: ({
        colors
      }) => darken(colors.tonalOffset, colors.webhook.main),
      contrastText: '#ffffff'
    },
    text: {
      primary: '#333333',
      secondary: ({
        colors
      }) => lighten(colors.tonalOffset, colors.text.primary)
    },
    border: {
      dark: 'rgba(0,0,0, 0.1)',
      light: '#ffffff'
    },
    responses: {
      success: {
        color: ({
          colors
        }) => colors.success.main,
        backgroundColor: ({
          colors
        }) => transparentize(0.9, colors.success.main)
      },
      error: {
        color: ({
          colors
        }) => colors.error.main,
        backgroundColor: ({
          colors
        }) => transparentize(0.9, colors.error.main)
      },
      redirect: {
        color: '#ffa500',
        backgroundColor: ({
          colors
        }) => transparentize(0.9, colors.responses.redirect.color)
      },
      info: {
        color: '#87ceeb',
        backgroundColor: ({
          colors
        }) => transparentize(0.9, colors.responses.info.color)
      }
    },
    http: {
      get: '#6bbd5b',
      post: '#248fb2',
      put: '#9b708b',
      options: '#d3ca12',
      patch: '#e09d43',
      delete: '#e27a7a',
      basic: '#999',
      link: '#31bbb6',
      head: '#c167e4'
    },
    deprecation: {
      banner: '#d40350'
    }
  },
  schema: {
    linesColor: theme => lighten(theme.colors.tonalOffset, desaturate(theme.colors.tonalOffset, theme.colors.primary.main)),
    defaultDetailsWidth: '75%',
    typeNameColor: theme => theme.colors.text.secondary,
    typeTitleColor: theme => theme.schema.typeNameColor,
    requireLabelColor: theme => theme.colors.error.main,
    labelsTextSize: '0.9em',
    nestingSpacing: '1em',
    nestedBackground: '#fafafa',
    arrow: {
      size: '1.1em',
      color: theme => theme.colors.text.secondary
    }
  },
  typography: {
    fontSize: '16px',
    lineHeight: '1.5em',
    fontWeightThin: '200',
    fontWeightLight: '300',
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightBold: '700',
    fontWeightBlack: '900',
    fontFamily: 'Inter, sans-serif',
    smoothing: 'antialiased',
    optimizeSpeed: true,
    headings: {
      fontFamily: 'Inter, sans-serif',
      fontWeight: '600',
      lineHeight: '130%'
    },
    code: {
      fontSize: '13px',
      fontFamily: 'Courier, monospace',
      lineHeight: ({
        typography
      }) => typography.lineHeight,
      fontWeight: ({
        typography
      }) => typography.fontWeightRegular,
      color: '#e53935',
      backgroundColor: 'rgba(38, 50, 56, 0.05)',
      wrap: false
    },
    links: {
      color: ({
        colors
      }) => colors.primary.main,
      visited: ({
        typography
      }) => typography.links.color,
      hover: ({
        typography
      }) => lighten(0.2, typography.links.color)
    }
  },
  navBar: {
    height: '80px',
    textColor: 'white',
    backgroundColor: '#f8f9fd',
    linkColorHover: '#2176AE',
    linkColorActive: '#2176AE',
    linkBGColorActive: '#E0ECF4'
  },
  menu: {
    width: '300px',
    backgroundColor: '#f8f9fd',
    backgroundColorActive: '#f8f9fd',
    linkColorActive: '#2176AE',
    linkBGColorActive: '#E0ECF4',
    textColor: '#424242',
    groupItems: {
      textTransform: 'capitalize'
    },
    level1Items: {
      textTransform: 'capitalize'
    },
    arrow: {
      size: '1.5em',
      color: theme => theme.menu.textColor
    },
    activeItem: {
      backgroundColor: '#ffffff'
    }
  },
  logo: {
    maxHeight: ({
      menu
    }) => menu.width,
    maxWidth: ({
      menu
    }) => menu.width,
    gutter: '2px'
  },
  rightPanel: {
    backgroundColor: '#3F495C',
    width: '40%',
    textColor: '#ffffff'
  },
  codeSample: {
    backgroundColor: '#0C0C25'
  }
};
export default defaultTheme;
export enum FooterLinksE {
  'TUTORIALS' = 'TUTORIALS',
  'SUPPORT' = 'SUPPORT',
  'RELEASE_NOTES' = 'RELEASE_NOTES',
  'GLOSSARY' = 'GLOSSARY',
}
export enum NavBarLinksE {
  'PRODUCTS' = 'PRODUCTS',
  'ESSENTIALS' = 'ESSENTIALS',
  'TUTORIALS' = 'TUTORIALS',
  'RELEASE_NOTES' = 'RELEASE_NOTES',
  'PUBLIC_ROADMAP' = 'PUBLIC_ROADMAP',
  'INTEGRATIONS' = 'INTEGRATIONS',
  'API' = 'API',
  'SDKS' = 'SDKS',
}
export function resolveTheme(theme: ThemeInterface): ResolvedThemeInterface {
  const resolvedValues = {};
  let counter = 0;
  const setProxy = (obj, path: string) => {
    Object.keys(obj).forEach(k => {
      const currentPath = (path ? path + '.' : '') + k;
      const val = obj[k];
      if (typeof val === 'function') {
        Object.defineProperty(obj, k, {
          get() {
            if (!resolvedValues[currentPath]) {
              counter++;
              if (counter > 1000) {
                throw new Error(`Theme probably contains circular dependency at ${currentPath}: ${val.toString()}`);
              }
              resolvedValues[currentPath] = val(theme);
            }
            return resolvedValues[currentPath];
          },
          enumerable: true
        });
      } else if (typeof val === 'object') {
        setProxy(val, currentPath);
      }
    });
  };
  setProxy(theme, '');
  return JSON.parse(JSON.stringify(theme));
}
export interface ColorSetting {
  main: string;
  light: string;
  dark: string;
  contrastText: string;
}
export interface HTTPResponseColos {
  color: string;
  backgroundColor: string;
}
export interface FontSettings {
  fontSize: string;
  fontWeight: string;
  fontFamily: string;
  lineHeight: string;
  color: string;
}
export interface ResolvedThemeInterface {
  links: {
    supportUrl: string;
    statusUrl: string;
  };
  homepage: {
    hideNotWorking: boolean;
  };
  footer: {
    links: FooterLinksE[];
    hideRightPanel?: boolean;
    gap: string;
  };
  texts: {
    copyRight: string;
  };
  spacing: {
    unit: number;
    sectionHorizontal: number;
    sectionVertical: number;
  };
  breakpoints: {
    small: string;
    medium: string;
    large: string;
  };
  navbar: {
    links: NavBarLinksE[];
    backgroundColor: string;
    linkBGColorActive: string;
    linkColorHover: string;
    linkColorActive: string;
  };
  colors: {
    tonalOffset: number;
    primary: ColorSetting;
    success: ColorSetting;
    warning: ColorSetting;
    error: ColorSetting;
    deprecated: ColorSetting;
    webhook: ColorSetting;
    border: {
      light: string;
      dark: string;
    };
    text: {
      primary: string;
      secondary: string;
    };
    responses: {
      success: HTTPResponseColos;
      error: HTTPResponseColos;
      redirect: HTTPResponseColos;
      info: HTTPResponseColos;
    };
    http: {
      get: string;
      post: string;
      put: string;
      options: string;
      patch: string;
      delete: string;
      basic: string;
      link: string;
      head: string;
    };
    deprecation: {
      banner: string;
    };
  };
  schema: {
    linesColor: string;
    defaultDetailsWidth: string;
    typeNameColor: string;
    typeTitleColor: string;
    requireLabelColor: string;
    labelsTextSize: string;
    nestingSpacing: string;
    nestedBackground: string;
    arrow: {
      size: string;
      color: string;
    };
  };
  typography: {
    fontSize: string;
    lineHeight: string;
    fontWeightThin: string;
    fontWeightLight: string;
    fontWeightRegular: string;
    fontWeightMedium: string;
    fontWeightBold: string;
    fontWeightBlack: string;
    fontFamily: string;
    smoothing: string;
    optimizeSpeed: boolean;
    code: FontSettings & {
      backgroundColor: string;
      wrap: boolean;
    };
    headings: {
      fontFamily: string;
      fontWeight: string;
      lineHeight: string;
    };
    links: {
      color: string;
      visited: string;
      hover: string;
    };
  };
  navBar: {
    height: string;
    backgroundColor: string;
    textColor: string;
    linkColorHover: string;
    linkColorActive: string;
    linkBGColorActive: string;
  };
  menu: {
    width: string;
    backgroundColor: string;
    backgroundColorActive: string;
    linkColorActive: string;
    linkBGColorActive: string;
    textColor: string;
    groupItems: {
      textTransform: string;
    };
    level1Items: {
      textTransform: string;
    };
    arrow: {
      size: string;
      color: string;
    };
    activeItem: {
      backgroundColor: string;
    };
  };
  logo: {
    maxHeight: string;
    maxWidth: string;
    gutter: string;
    url: string;
  };
  rightPanel: {
    backgroundColor: string;
    textColor: string;
    width: string;
  };
  codeSample: {
    backgroundColor: string;
  };
  extensionsHook?: (name: string, props: any) => string;
}
export type primitive = string | number | boolean | undefined | null;
export type AdvancedThemeDeep<T> = T extends primitive ? T | ((theme: ResolvedThemeInterface) => T) : AdvancedThemeObject<T>;
export type AdvancedThemeObject<T> = { [P in keyof T]?: AdvancedThemeDeep<T[P]> };
export type ThemeInterface = AdvancedThemeObject<ResolvedThemeInterface>;