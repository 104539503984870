import * as React from 'react';
import { TitleSection, StyledUl } from '../HomePage/HomePageComponents';
import { H1 } from '../../../common-elements/headers';
import { MiddlePanel } from '../../../common-elements';
import { observer } from 'mobx-react';
import { StyledP } from '../../NavBar/styled.elements';
import { AppStore } from '../../../services';
import { FormattedHtmlMessage, __ } from 'ib-i18n';
@observer
export class SMSGetStarted extends React.Component<{
  store: AppStore;
}> {
  render() {
    const {
      docsUrl,
      portalUrl,
      signupUrl,
      loginUrl,
      docsUrlEncoded
    } = this.props.store.options;
    return <>
        <MiddlePanel compact={true} full={true}>
          <H1>{__('Get Started with SMS')}</H1>
          <StyledP>
            {__('Send a simple SMS text by defining destination and message text, or experiment with a rich set of features, including bulk sending.')}
            <br />
            <br />
            <FormattedHtmlMessage message={__('To utilize SMS in combination with other channels, check out {messagesApi}.')} messagesApi={<a href={`${docsUrl}/api/platform/messages-api`}>{__('Messages API')}</a>} />
          </StyledP>
          <TitleSection id="send-your-first-message">{__('Send your first message!')}</TitleSection>
          <StyledP>
            <ol>
              <li>
                <FormattedHtmlMessage message={/* prettier-ignore */
              __('<b>Infobip account</b> is required to send SMS messages. {signup} or {login} if you haven\'t already. Follow the verification and customization steps to set up your Infobip account.')} signup={<a href={signupUrl}>{__('Create one')}</a>} login={<a href={`${loginUrl}/?callback=${docsUrlEncoded}%2Fapi%23channels%2Fsms`}>
                      {__('log in')}
                    </a>} />
              </li>
              <li>
                {/* prettier-ignore */
              __('During the free trial period, you\'ll be able to send 100 free SMS messages to up to 5 recipients with verified numbers, respecting country-specific SMS regulations.')}
              </li>
              <li>
                {__('Throught the trial period, you can only send messages to the number you have verified during the sign-up process.')}
              </li>
              <li>
                <FormattedHtmlMessage message={/* prettier-ignore */
              __('Infobip\'s test sender (ServiceSMS) will be at your disposal along with <b>demo sender ID</b>.')} />
              </li>
              <li>
                {/* prettier-ignore */
              __('You\'ll be able to test SMS Broadcast, Infobip\'s one-way messaging tool accessible from your Infobip account. Use it to send SMS messages in bulk to up to 5 additional mobile numbers.')}
              </li>
              <li>
                <FormattedHtmlMessage message={__('Visit {analyze} to check your message performance, quality of delivery, etc.')} analyze={<a href={`${portalUrl}/analyze/dashboard/`}>
                      <b>{__('Analyze')}</b>
                    </a>} />
              </li>
            </ol>
            <FormattedHtmlMessage message={/* prettier-ignore */
          __('Every API request you make should contain the following Infobip account\'s unique data: properly configured {baseUrl} and <b>Authentication</b> as described in {documentation}.')} baseUrl={<a href={`${docsUrl}/api/authentication#base-url-and-data-centers`}>
                  {__('Base URL')}
                </a>} documentation={<a href={`${docsUrl}/api/#authentication`}>{__('documentation')}</a>} />
          </StyledP>
          <TitleSection>{__('Other languages and advanced functionalities')}</TitleSection>
          <StyledP>
            <FormattedHtmlMessage message={__('For more information on usage and code examples explore {smsApiReference}.')} smsApiReference={<a href={`${docsUrl}/api#channels/sms/send-sms-message`}>
                  {__('Send SMS API reference')}
                </a>} />
          </StyledP>
          <TitleSection>{__('Next steps')}</TitleSection>
          <StyledUl>
            <li>
              <a href={`${docsUrl}/sdk`}>{__('Get API client library')}</a>
            </li>
            <li>
              <a href={`${portalUrl}/payments`}>{__('Upgrade account')}</a>
            </li>
            <li>
              <a href={`${portalUrl}/apps/numbers`}>{__('Buy number')}</a>
            </li>
            <li>
              <FormattedHtmlMessage message={__('Explore {smsAdvancedOptions}, {deliveryReports}, {howTo} and other SMS APIs.')} smsAdvancedOptions={<a href={`${docsUrl}/api#channels/sms/send-sms-message`}>
                    {__('SMS advanced options')}
                  </a>} deliveryReports={<a href={`${docsUrl}/api#channels/sms/receive-outbound-sms-message-report`}>
                    {__('delivery reports')}
                  </a>} howTo={<a href={`${docsUrl}/api#channels/sms/receive-inbound-sms-messages`}>
                    {__('how to receive a message')}
                  </a>} />
            </li>
          </StyledUl>
          <TitleSection>{__('Need help?')}</TitleSection>
          <StyledP>
            <FormattedHtmlMessage message={__('In case you get stuck at any point, visit {getStarted} to find any information you need to successfully use Infobip API.')} getStarted={<a href={`${docsUrl}/api`}>{__('API Get started page')}</a>} />
          </StyledP>
        </MiddlePanel>
      </>;
  }
}