import { OpenAPIXVersions } from '../../types';
import { VersioningInfoBadgeLatest, VersioningInfoBadge, DropDownItem, DropDownItemText, DropdownItemTextSpan } from './styled.elements';
import React from 'react';
import { __ } from 'ib-i18n';
import checkmarkIcon from '../../images/checkmark.svg';
import { versioningTitle } from './utils';
interface DropdownMenuItemProps {
  active: boolean;
  versionInformation: OpenAPIXVersions;
  title?: string;
}
export const DropdownMenuItem = ({
  active,
  versionInformation,
  title
}: DropdownMenuItemProps): React.JSX.Element => {
  const InfoBadge = versionInformation.latest ? VersioningInfoBadgeLatest : VersioningInfoBadge;
  const {
    latest,
    versionNumber
  } = versionInformation;
  const labelText = versioningTitle(latest, title);
  return <DropDownItem key={versionInformation.operationId} isActive={active}>
      <DropDownItemText>
        <DropdownItemTextSpan isActive={active}>{labelText}</DropdownItemTextSpan>
        <InfoBadge>{'V' + versionNumber}</InfoBadge>
      </DropDownItemText>
      {active && <img src={checkmarkIcon} alt={'checkmark'} />}
    </DropDownItem>;
};