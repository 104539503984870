import { OpenAPIXVersions } from '../../types';
import { __ } from 'ib-i18n';
export const isActive = (versionInformationOperationId: string, currentOperationId: string) => {
  return currentOperationId === versionInformationOperationId;
};
export const findPathForSuccessorOperation = (versionInformation: any, getItemByOperationId: (operationId: string) => any): string => {
  return getItemByOperationId(versionInformation.operationId)?.id || '';
};
export const getDropdownItemTitle = (versionInformation: OpenAPIXVersions, versionInformationList: OpenAPIXVersions[], getItemByOperationId: (operationId: string) => any) => {
  return calculateSameVersions(versionInformation.versionNumber, versionInformationList) > 1 ? getItemByOperationId(versionInformation.operationId)?.name : undefined;
};
const calculateSameVersions = (versionNumber: number, versionInformationList: OpenAPIXVersions[]): number => {
  return versionInformationList.reduce((count, versionInformation) => {
    return versionInformation.versionNumber === versionNumber ? count + 1 : count;
  }, 0);
};
export const versioningTitle = (latest: boolean, title?: string) => {
  return latest ? __('Latest API') : title ?? __('API');
};