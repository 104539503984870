import * as React from 'react';
import { ArrowDownSvg } from './ArrowDownSvg';
import { findPathForSuccessorOperation, getDropdownItemTitle, isActive } from './utils';
import { OpenAPIXVersions } from '../../types';
import { DropdownMenuItem } from './DropdownMenuItem';
import { ALIGNMENT, Dropdown, DROPDOWN_CONTENT_WIDTH, DropdownButton, DropdownContent, DropdownItems, DropDownMoreWrapper, DropdownTabWrapper } from './styled.elements';
import { SideMenuLink } from '../Link';
interface DropdownMenuProps {
  overflowedTabs: OpenAPIXVersions[];
  versionInformationList: OpenAPIXVersions[];
  currentOperationId: string;
  getItemByOperationId: (operationId: string) => any;
  containerRef: React.RefObject<HTMLDivElement>; // Reference for container alignment
}
export const DropdownMenu = ({
  overflowedTabs,
  versionInformationList,
  currentOperationId,
  getItemByOperationId,
  containerRef
}: DropdownMenuProps) => {
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);
  const [isOpened, setIsOpened] = React.useState(false);
  const [alignRight, setAlignRight] = React.useState(false);
  const handleOpen = () => {
    setIsOpened(!isOpened);
  };
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isOpened, containerRef]);
  const handleResize = () => {
    const dropdownElement = dropdownRef.current;
    if (dropdownElement && containerRef.current) {
      const dropdownRect = dropdownElement.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      const alignmentConstant = DROPDOWN_CONTENT_WIDTH - (dropdownElement.clientWidth + ALIGNMENT);
      if (dropdownRect.right + alignmentConstant > containerRect.right) {
        setAlignRight(true);
      } else {
        setAlignRight(false);
      }
    }
  };
  React.useEffect(() => {
    setIsOpened(false);
  }, [currentOperationId]);
  return <DropdownTabWrapper ref={dropdownRef}>
      <Dropdown>
        <DropdownButton onClick={handleOpen}>
          <DropDownMoreWrapper>
            <span>More</span>
          </DropDownMoreWrapper>
          <ArrowDownSvg isOpened={isOpened} />
        </DropdownButton>
        {isOpened && <DropdownContent alignRight={versionInformationList.length - overflowedTabs.length !== 1 ? alignRight : undefined}>
            <DropdownItems>
              {overflowedTabs.map(versionInformation => <SideMenuLink to={findPathForSuccessorOperation(versionInformation, getItemByOperationId)} key={versionInformation.operationId}>
                  <DropdownMenuItem active={isActive(versionInformation.operationId, currentOperationId)} versionInformation={versionInformation} title={getDropdownItemTitle(versionInformation, versionInformationList, getItemByOperationId)} />
                </SideMenuLink>)}
            </DropdownItems>
          </DropdownContent>}
      </Dropdown>
    </DropdownTabWrapper>;
};