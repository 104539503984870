import * as JsonSchemaRefParser from 'json-schema-ref-parser';
import axiosRetry from 'axios-retry';
import axios, { AxiosError } from 'axios';
/* tslint:disable-next-line:no-implicit-dependencies */
import { OpenAPISpec } from '../types';
const client = axios.create({
  baseURL: '',
  timeout: 60000,
  withCredentials: false
});
axiosRetry(client, {
  retries: 10,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error: AxiosError<unknown, any>) => {
    const shouldRetry = axiosRetry.isNetworkOrIdempotentRequestError(error) || error.response?.status && error.response?.status >= 500;
    return !!shouldRetry;
  }
});
export async function loadAndBundleSpec(specUrlOrObject: object | string): Promise<OpenAPISpec> {
  const parser = new JsonSchemaRefParser();
  const specObj = typeof specUrlOrObject === 'string' ? (await client.get(specUrlOrObject)).data : specUrlOrObject;
  return (await parser.bundle(specObj, ({
    resolve: {
      http: {
        withCredentials: false
      }
    }
  } as object)) as any);
}