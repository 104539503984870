import * as React from 'react';
import { UnderlinedHeader } from '../../common-elements/';
import { SecurityRequirementModel, SecurityScheme } from '../../services/models/SecurityRequirement';
import styled from '../../styled-components';
import { linksCss } from '../Markdown/styled.elements';
import { __ } from 'ib-i18n';
import { InfoSvg } from './InfoSvg';
import { AppStore } from '../..';
const ScopeName = styled.code`
  font-size: ${props => props.theme.typography.code.fontSize};
  font-family: ${props => props.theme.typography.code.fontFamily};
  border: 1px solid ${({
  theme
}) => theme.colors.border.dark};
  margin: 0 3px;
  padding: 0.2em;
  display: inline-block;
  line-height: 1;

  &:after {
    content: ',';
  }
  &:last-child:after {
    content: none;
  }
`;
const SecurityRequirementAndWrap = styled.span`
  &:after {
    font-weight: bold;
  }

  &:last-child:after {
    content: none;
  }

  ${linksCss};
`;
const SecurityRequirementOrWrap = styled.span`
  padding-right: 15px;
  white-space: nowrap;

  &:last-child {
    padding-right: 0;
  }

  ${linksCss};

  @media screen and (max-width: 767px) {
    display: list-item;
    margin-left: 30px;
    font-size: 16px;
    margin-top: 10px;
    border: 0;
    padding: 0;
    white-space: normal;
  }
`;
const SecuritySeparator = styled.div`
  padding-right: 15px;
  border-left: 1px solid #ccc;

  @media screen and (max-width: 767px) {
    border-right: 0;
  }
`;
export interface SecurityRequirementProps {
  security: SecurityRequirementModel;
  store?: AppStore;
}
const secSchemeToDocsUrl = {
  Basic: '/essentials/api-essentials/api-authentication#basic',
  APIKeyHeader: '/essentials/api-essentials/api-authentication#api-key-header',
  IBSSOTokenHeader: '/essentials/api-essentials/api-authentication#ibsso-token-header',
  OAuth2: '/essentials/api-essentials/api-authentication#oauth-20'
};
const scopesDocsUrl = 'https://www.infobip.com/docs/essentials/api-essentials/api-authorization';
const authorizationReadable = {
  Basic: 'Basic',
  APIKeyHeader: 'API Key Header',
  APIKeyCookie: 'API Key Cookie',
  IBSSOTokenHeader: 'IBSSO Token Header',
  IBSSOTokenCookie: 'IBSSO Token Cookie',
  OAuth2: 'OAuth 2'
};
export class SecurityRequirement extends React.PureComponent<SecurityRequirementProps> {
  isDocumented(securityScheme: SecurityScheme) {
    return secSchemeToDocsUrl[securityScheme.id];
  }
  getReadableAuthorization(securityScheme: SecurityScheme) {
    return authorizationReadable[securityScheme.id];
  }
  render() {
    const security = this.props.security;
    const store = this.props.store;
    return <SecurityRequirementOrWrap>
        {security.schemes.filter(this.isDocumented).map(scheme => {
        return <SecurityRequirementAndWrap key={scheme.id}>
              <a href={`${store?.options.docsUrl}${secSchemeToDocsUrl[scheme.id]}`}>
                {this.getReadableAuthorization(scheme)}
              </a>
              {scheme.scopes.length > 0 && ' ('}
              {scheme.scopes.map(scope => <ScopeName key={scope}>{scope}</ScopeName>)}
              {scheme.scopes.length > 0 && ') '}
            </SecurityRequirementAndWrap>;
      })}
      </SecurityRequirementOrWrap>;
  }
}
export const AuthHeaderColumn = styled.div`
  display: table-cell;
  min-width: 12em;
  width: 25%;
`;
const SecuritiesColumn = styled.div`
  width: ${props => props.theme.schema.defaultDetailsWidth};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: 767px) {
    display: ul;
    flex-direction: column;
    color: ${props => props.theme.colors.primary.main};
  }
}
`;
const ToolTipContainer = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
`;
export const AuthHeader = styled(UnderlinedHeader)`
  color: #263238;
  opacity: 0.5;
  font-size: 15px;
  border-bottom: 0;
  margin: 0;
`;
const Wrap = styled.div`
  width: 100%;
  display: flex;
  margin: 1em 0;

  @media screen and (max-width: 767px) {
    align-items: baseline;
    display: flex;
    flex-direction: column;
  }
`;
export interface SecurityRequirementsProps {
  securities: SecurityRequirementModel[];
  scopes: string[];
  store?: AppStore;
}
export class SecurityRequirements extends React.PureComponent<SecurityRequirementsProps> {
  isDocumented(securityRequirement: SecurityRequirementModel) {
    return undefined !== securityRequirement.schemes.map(s => s.id).find(id => secSchemeToDocsUrl[id]);
  }
  render() {
    const securities = this.props.securities;
    const scopes = this.props.scopes;
    return <>
        {this.renderAuthorization(securities)}
        {this.renderScopes(scopes)}
      </>;
  }
  renderAuthorization(securities: SecurityRequirementModel[]) {
    if (!securities.length) {
      return <></>;
    }
    const store = this.props.store;
    return <Wrap>
        <AuthHeaderColumn>
          <AuthHeader>{`${__('Authorizations')}: `}</AuthHeader>
        </AuthHeaderColumn>
        <SecuritiesColumn>
          {securities.filter(this.isDocumented).sort((a, b) => {
          return a.schemes[0].id.localeCompare(b.schemes[0].id);
        }).map((security, idx) => <>
                <SecurityRequirement key={idx} security={security} store={store} />
                {idx < securities.length - 1 && <SecuritySeparator />}
              </>)}
        </SecuritiesColumn>
      </Wrap>;
  }
  renderScopes(scopes: string[]) {
    if (!scopes.length) {
      return <></>;
    }
    return <Wrap>
        <AuthHeaderColumn>
          <AuthHeader>
            <ToolTipContainer title={__('You must have at least one of these scopes to access this end-point')}>
              {`${__('Scopes')}`}
              <InfoSvg />:
            </ToolTipContainer>
          </AuthHeader>
        </AuthHeaderColumn>
        <SecuritiesColumn>
          {scopes.sort((a, b) => a.localeCompare(b)).map((item, index) => <>
                <SecurityRequirementOrWrap key={index}>
                  <SecurityRequirementAndWrap>
                    <a href={scopesDocsUrl}>{item}</a>
                  </SecurityRequirementAndWrap>
                </SecurityRequirementOrWrap>
                {index < scopes.length - 1 && <SecuritySeparator />}
              </>)}
        </SecuritiesColumn>
      </Wrap>;
  }
}